import React from "react";
import { Link } from "gatsby";
import logo from "../img/ccc-acronym-logo.png";

const Navbar = class extends React.Component {
  render() {
    return (
      <header>
        <Link to="/" title="Logo">
          <img src={logo} alt="ACRONYM" />
        </Link>
      </header>
    );
  }
};

export default Navbar;
