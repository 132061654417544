import React from "react";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="copyright">
              Copyright 2019 ACRONYM.
              <br className="hidden-md-up" />
              All Rights Reserved.
            </div>
            <ul className="d-md-inline-flex ">
              <li>
                <a href="https://www.anotheracronym.org/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
