import React from "react";
import PropTypes from "prop-types";
// import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <div className="container">
    <div className="row">
      {gridItems.map(item => (
        <div key={item.text}  className="col-md-4 col-lg-2 flex-md-nowrap stats">
            <div className="stats-header">{item.number}</div>
            <div>{item.text}</div>
        </div>
      ))}
    </div>
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.string
    })
  )
};

export default FeatureGrid;
