import React from "react";
import MobileDetect from "mobile-detect";
import { Carousel } from "../components/Carousel";
import { isNull } from "lodash";

let isMobile = false;

// conditional required for production build
if (typeof navigator !== "undefined") {
  const md = new MobileDetect(navigator.userAgent);
  isMobile = !isNull(md.mobile());
}

export class CarouselResponsive extends React.Component {
  render() {
    const settings = isMobile
      ? {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      : {
          dots: false,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3
        };
    return <Carousel {...settings} />;
  }
}
