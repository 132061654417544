import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import Form from "../components/Form";
import { CarouselResponsive } from "../components/CarouselResponsive";
import logo from "../img/acronym-logo-white.png";
import contactImage from "../img/curious.jpg";

export const IndexPageTemplate = ({
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
  button,
  about,
  contact
}) => {
  if (typeof document !== "undefined") {
    document.body.className = "page-home";
  }

  return (
    <div className="container-fluid">
      <div
        className="wrapper"
        style={{
          display: "flex",
          lineHeight: "1",
          justifyContent: "space-around",
          alignItems: "left",
          flexDirection: "column"
        }}
      >
        <h1 className="has-text-weight-bold">{title}</h1>
        <h2>
          Do you ever find yourself wondering why so many{" "}
          <span className="redact redact-1" /> political ads are so... terrible?{" "}
          <span className="redact redact-2" /> Yeah, us too. That's why ACRONYM
          is committed to <span className="redact redact-3" /> flipping the
          script on political ads by working with people to create content{" "}
          <span className="redact redact-4" /> that both informs and inspires{" "}
          <span className="redact redact-5" /> audiences to take action.
        </h2>
      </div>

      <div className="text-center">
        <Link className="btn" to="#join">
          {button}
        </Link>
      </div>
      <section className="section text-white bg-dark hit-pieces">
        <div className="container container-fluid">
          <div className="wrapper">
            <h3 className="title">{mainpitch.title}</h3>
            <div>{mainpitch.description}</div>
          </div>
          <section className="carousel">
            <CarouselResponsive />
          </section>
          <div className="wrapper">{mainpitch.results}</div>
          <h4 className="redact redact-white-2">
            <span>{mainpitch.resultsheading}</span>
          </h4>
          <Features gridItems={intro.blurbs} />
        </div>
      </section>
      <section className="contact-form" id="join">
        <Form />
      </section>
      <section className="about text-white bg-dark">
        <div className="row d-flex flex-column">
          <div className="text-center">
            <h4 className="hidden-md-up">ABOUT</h4>
          </div>
          <div className="text-center">
            <img src={logo} alt="ACRONYM logo" className="about-logo" />
          </div>
          <div>{about.description}</div>
        </div>
      </section>

      <section className="contact">
        <div className="row d-flex">
          <div className="col col-md-6 hidden-sm-down">
            <img src={contactImage} alt="Contact" />
          </div>
          <div className="col col-12 col-md-6 d-flex flex-column">
            <h4>{contact.title}</h4>
            <div className="text-center">
              Contact <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  about: PropTypes.object,
  contact: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        button={frontmatter.button}
        mainpitch={frontmatter.mainpitch}
        about={frontmatter.about}
        contact={frontmatter.contact}
        // description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        # image {
        #   childImageSharp {
        #     fluid(maxWidth: 2048, quality: 100) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        # }
        subheading
        button
        mainpitch {
          title
          description
          results
          resultsheading
        }
        about {
          title
          description
        }
        contact {
          title
          email
        }
        # description
        intro {
          blurbs {
            # image {
            #   childImageSharp {
            #     fluid(maxWidth: 240, quality: 64) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            # }
            number
            text
          }
          description
        }
      }
    }
  }
`;
