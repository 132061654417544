import React, { Fragment } from "react";
import { navigate } from "gatsby"

const Form = class extends React.Component {
  render() {
    let submitted = false;

    const isSubmitted = () => {
      if (submitted) {
        navigate("/success/")
      }
    };

    const isValid = () => {
      const firstName = document.querySelector('#first-name').value;
      const lastName = document.querySelector('#last-name').value;
      const email = document.querySelector('#email').value;
      // eslint-disable-next-line
      const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const validEmail = emailRegEx.test(email);
      const submitButton = document.querySelector('.btn-submit');

      if (
        firstName &&
        lastName &&
        email &&
        validEmail
      ) {
        submitButton.removeAttribute('disabled');
      } else {
        submitButton.setAttribute('disabled', true);
      }
    };

    return (
      <Fragment>
        <h4>JOIN THE CONSPIRACY</h4>
        <iframe
          name="hidden-iframe"
          title="submit handler"
          id="hidden-iframe"
          style={{ display: "none" }}
          onLoad={isSubmitted}
        />
        <form
          action="https://docs.google.com/forms/d/e/1FAIpQLSdzE3TzMIkngthubScnZZ1WDY6o7US72WncaL4h0I9dWLDL8g/formResponse"
          method="POST"
          id="mG61Hd"
          target="hidden-iframe"
          onChange= {isValid}
          onSubmit={() => {
            const submitButton = document.querySelector('.btn-submit');
            submitButton.className += ' loading';
            submitted = true;
          }}
          className="u-center"
        >
          <div className="row">
            <div className="col col-12 col-md-6 first-name">
              <label className="required">First Name <span>Required</span></label>
              <input
                type="text"
                id="first-name"
                tabIndex="1"
                aria-label="First Name"
                aria-describedby="i.desc.1743370252 i.err.1743370252"
                name="entry.1451787040"
                defaultValue=""
              />
            </div>
            <div className="col col-12 col-md-6">
              <label className="required">Last Name <span>Required</span></label>
              <input
                type="text"
                id="last-name"
                tabIndex="2"
                aria-label="Last Name"
                aria-describedby="i.desc.1816519788 i.err.1816519788"
                name="entry.697964610"
                defaultValue=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="required">Email <span>Required</span></label>
              <input
                type="text"
                id="email"
                tabIndex="3"
                aria-label="Email"
                aria-describedby="i.desc.810151887 i.err.810151887"
                name="entry.578614526"
                defaultValue=""
              />
            </div>
          </div>
          <div className="row contribute">
            <div className="col col-md-6 offset-md-3 text-center mt-3 label-contribute">
              <h5>HOW YOU CAN VOLUNTEER</h5>
              <div>[Check all that apply]</div>
            </div>
          </div>
          <div className="row contribute">
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Photography"
                type="checkbox"
                tabIndex="3"
              />
              <label>Photography</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Design + Illustration"
                type="checkbox"
                tabIndex="4"
              />
              <label>Design + Illustration</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Animation"
                type="checkbox"
                tabIndex="5"
              />
              <label>Animation</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Scriptwriting + Copywriting"
                type="checkbox"
                tabIndex="6"
              />
              <label>Scriptwriting + Copywriting</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Editing"
                type="checkbox"
                tabIndex="7"
              />
              <label>Editing</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Directing"
                type="checkbox"
                tabIndex="8"
              />
              <label>Directing</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Production"
                type="checkbox"
                tabIndex="9"
              />
              <label>Production</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Music + Composition"
                type="checkbox"
                tabIndex="10"
              />
              <label>Music + Composition</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="On Screen Talent + Voice-Over"
                type="checkbox"
                tabIndex="11"
              />
              <label>On Screen Talent + Voice-Over</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Concept Development"
                type="checkbox"
                tabIndex="12"
              />
              <label>Concept Development</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Marketing Development"
                type="checkbox"
                tabIndex="13"
              />
              <label>Marketing Development</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Social Amplification"
                type="checkbox"
                tabIndex="14"
              />
              <label>Social Amplification</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Talent Sourcing"
                type="checkbox"
                tabIndex="15"
              />
              <label>Talent Sourcing</label>
            </div>
            <div className="col col-12 col-md-4 d-inline-flex justify-content-start">
              <input
                name="entry.98036120"
                jsname="ekGZBc"
                value="Crew Sourcing"
                type="checkbox"
                tabIndex="16"
              />
              <label>Crew Sourcing</label>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <input
                disabled
                className="btn btn-submit"
                type="submit"
                value="Submit"
                tabIndex="17"
              />
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
};

export default Form;
