import React from "react";
import Slider from "react-slick";

export const Carousel = props => (
  <Slider {...props}>
    <div className="slide">
      <iframe
        src="https://www.youtube.com/embed/TbZ_uDGTjyw"
        title="STEVE: DON'T VOTE"
        allowFullScreen
      />
      <div className="caption">STEVE: DON'T VOTE</div>
    </div>
    <div className="slide">
      <iframe
        src="https://www.youtube.com/embed/iphKlXj-kyE"
        title="DEAR YOUNG PEOPLE"
        allowFullScreen
      />
      <div className="caption">DEAR YOUNG PEOPLE</div>
    </div>
    <div className="slide">
      <iframe
        src="https://www.youtube.com/embed/Lg6UHPpXXYk"
        title="CALL THE COPS"
        allowFullScreen
      />
      <div className="caption">CALL THE COPS</div>
    </div>
    <div className="slide">
      <video controls className="video-vertical">
        <source src="https://s3.amazonaws.com/2019-creative-collusion/bojack.webm" type="video/webm" />
        <source src="https://s3.amazonaws.com/2019-creative-collusion/bojack.mp4" type="video/mp4" />
        <source src="https://s3.amazonaws.com/2019-creative-collusion/bojack.ogg" type="video/ogg" />
        Sorry - your browser doesn't support HTML5 video in WebM, MP4 or OGG.
      </video>
      <div className="caption">Bojack Swipe Up</div>
    </div>
    <div className="slide">
      <video controls className="video-square">
        <source src="https://s3.amazonaws.com/2019-creative-collusion/VoteAnimations.webm" type="video/webm" />
        <source src="https://s3.amazonaws.com/2019-creative-collusion/VoteAnimations.mp4" type="video/mp4" />
        <source src="https://s3.amazonaws.com/2019-creative-collusion/VoteAnimations.ogg" type="video/ogg" />
        Sorry - your browser doesn't support HTML5 video in WebM, MP4 or OGG.
      </video>
      <div className="caption">Vote Animations</div>
    </div>
    <div className="slide">
      <iframe
        src="https://www.youtube.com/embed/b_ZrhlW8Z1M"
        title="Steve: Medical Bills"
        allowFullScreen
      />
      <div className="caption">Steve: Medical Bills</div>
    </div>
    <div className="slide">
      <iframe
        src="https://www.youtube.com/embed/5LpFSmcuHfg"
        title="Steve: Water Quality"
        allowFullScreen
      />
      <div className="caption">Steve: Water Quality</div>
    </div>
    <div className="slide">
      <iframe
        src="https://www.youtube.com/embed/SsNDAqPuRXE"
        title="Call the Cops: Lemonade"
        allowFullScreen
      />
      <div className="caption">Call the Cops: Lemonade</div>
    </div>
    <div className="slide">
      <iframe
        src="https://www.youtube.com/embed/wHITY51VNCI"
        title="Universal Healthcare? Don't Vote"
        allowFullScreen
      />
      <div className="caption">Universal Healthcare? Don't Vote</div>
    </div>
    <div className="slide">
      <iframe
        src="https://www.youtube.com/embed/Ndrhf7cl4mk"
        title="Global Warming? Climate Change? Don't Vote."
        allowFullScreen
      />
      <div className="caption">Global Warming? Climate Change? Don't Vote.</div>
    </div>
  </Slider>
);
